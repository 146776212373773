<template>
  <div class="app-context expanded">
    <app-header :headerOption="headerOption" ref="appheader"></app-header>
    <div class="context modify">
      <directive :directiveInfo="directiveInfo"></directive>
      <div class="join document">
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">수신병원</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.rcvHospNm"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료의사</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.rcvDrNm"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단코드</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.diagCd"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진료과목</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.rcvParts"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">환자명</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.patNm"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">생년월일</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.patBirth"></span>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">성별</h5>
          </div>
          <div class="radio-input-wrap">
            <div class="item">
              <input
                type="radio"
                id="male"
                v-model="requestDetail.patSex"
                value="1"
                :disabled="requestDetail.patSex !== ''"
              />
              <label for="male">남성</label>
            </div>
            <div class="item">
              <input
                type="radio"
                id="female"
                v-model="requestDetail.patSex"
                value="0"
                :disabled="requestDetail.patSex !== ''"
              />
              <label for="female">여성</label>
            </div>
          </div>
        </div>
        <div class="j-article">
          <div class="title-block">
            <h5 class="title">연락처</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.patTelno"></span>
          </div>
        </div>

        <div class="j-article">
          <div class="title-block">
            <h5 class="title">진단명 또는 주증상</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.diagNm"></span>
          </div>
        </div>
        <div
          class="j-article"
          :class="{
            last:
              requestDetail.referAttchId === '' &&
              requestDetail.visitYn === 'N',
          }"
        >
          <div class="title-block">
            <h5 class="title">환자상태 또는 의뢰내용</h5>
          </div>
          <div class="input-wrap">
            <span class="viewer" v-text="requestDetail.referConts"></span>
          </div>
        </div>
        <div
          class="j-article"
          v-if="requestDetail.referAttchId !== ''"
          :class="{
            last:
              requestDetail.rcvYn === 'N' &&
              (requestDetail.rcvConts == null || requestDetail.rcvConts == ''),
          }"
        >
          <div class="title-block">
            <h5 class="title">첨부파일</h5>
          </div>
          <div class="attachment-wrap">
            <h5 class="title">파일명 :</h5>
            <div class="attachment">
              <div class="a-input-wrap">
                <span
                  class="file-name"
                  v-text="requestDetail.referAttchNm"
                ></span>
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="download()">다운로드</button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="j-article last"
          v-if="
            requestDetail.rcvYn === 'Y' &&
              (requestDetail.rcvConts !== null || requestDetail.rcvConts !== '')
          "
        >
          <div class="title-block">
            <h5 class="title">회신내용</h5>
          </div>
          <div class="input-wrap">
            <textarea
              class="viewer"
              v-text="requestDetail.rcvConts"
              readonly
            ></textarea>
          </div>
        </div>
        <div
          class="store-btn-wrap"
          :class="{
            whole: requestDetail.visitYn === 'Y',
          }"
          v-if="isOwn"
        >
          <button type="button" class="prev" @click="goPrev()">이전으로</button>
          <button
            type="button"
            v-if="requestDetail.visitYn === 'N'"
            class="active"
            @click="hyperPdf()"
          >
            출력
          </button>
          <button
            type="button"
            v-if="requestDetail.visitYn === 'N'"
            @click="goModify(bringReferId)"
            class="active"
          >
            수정하기
          </button>
        </div>
        <div
          class="store-btn-wrap"
          :class="{
            tripple: requestDetail.visitYn === 'N',
          }"
          v-else
        >
          <button type="button" class="prev" @click="goPrev()">이전으로</button>
          <button type="button" class="active" @click="hyperPdf()">출력</button>
          <button
            type="button"
            v-if="requestDetail.rcvYn === 'N' && requestDetail.visitYn === 'N'"
            @click="goReplyComment(bringReferId)"
            class="active"
          >
            회신등록
          </button>
          <button
            type="button"
            v-if="requestDetail.visitYn === 'N'"
            @click="doVisit(bringReferId)"
            class="active"
          >
            내원확인
          </button>
          <button
            type="button"
            @click="goReplyRequest(bringReferId)"
            class="active"
          >
            되의뢰
          </button>
        </div>
      </div>
    </div>
    <app-footer></app-footer>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import Directive from "@/components/join/Directive";
import DefaultPopup from "@/components/modal/DefaultPopup";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";

export default {
  components: {
    AppHeader,
    AppFooter,
    Directive,
    DefaultPopup,
  },
  data() {
    return {
      headerOption: {
        navOrder: 1,
      },
      directiveInfo: {
        title: "진료의뢰서 확인",
        isTitleNewLine: false,
        content:
          "환자가 내원하지 않았다면\n의뢰서 수정 및 의뢰취소를 할 수 있습니다.",
        isContentNewLine: true,
        warn: null,
        isWarnNewLine: false,
      },
      requestDetail: [],
      popupSet: {},
      isOwn: false,
      attachFile: null,
      bringReferId: null,
    };
  },
  created() {
    this.getReferDetail();
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup"]),
    ...mapGetters("referList", ["GET_REFER_DETAIL"]),
    ...mapGetters("request", ["GET_REFER_ID"]),
    ...mapGetters("util", ["GET_PDF_URL"]),
  },
  methods: {
    ...mapMutations("basic", ["SET_POPUP"]),
    ...mapMutations("request", ["SET_REPLY_STATUS", "SET_REFER_ID"]),
    ...mapActions("referList", ["REFER_DETAIL"]),
    ...mapActions("request", ["VISIT_CHECK"]),
    ...mapActions("util", ["BRING_PDF_URL_ON_REFER", "BRING_PDF"]),
    async doVisit(referId) {
      //console.log("referId", referId);
      
      if(referId === null 
      || referId === undefined)
      referId = "";

      var referIdBoo = /^[0-9]{8,14}$/;

      if(!referIdBoo.test(referId)){
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 내원확인을 처리할 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
        return;
      }

      const payload = {
        referId: referId,
      };
      
      try {
        await this.VISIT_CHECK(payload).then(() => {
          this.requestDetail.visitYn = "Y";
        });
      } catch (error) {
        console.log("error:", error);
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 내원확인을 처리할 수 없습니다.";
        this.popupSet.popType = "warn";
        this.popupSet.cancelBtnText = "확인";
      }
    },
    goPrev() {
      this.$router.push("/v/mypage/request/0").catch(()=>{});
    },
    async hyperPdf() {
      await this.callPdf();
      await this.fileCall();
    },
    async fileCall() {
      try {
        await this.BRING_PDF(this.GET_PDF_URL.fileName).then(() => {
          const blob = new Blob([this.GET_PDF_URL], {
            type: "application/pdf",
          });
          const result = URL.createObjectURL(blob);
          //console.log("찾기", result);
          const url = "https://www.drrefer.net/pdf/" + result;
          //console.log("called_Pdf_url", url);

          window.open(result, "_blank");
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    async callPdf() {
      try {
        await this.BRING_PDF_URL_ON_REFER(this.bringReferId).then(() => {});
      } catch (error) {
        console.log("error:", error);
      }
    },
    domLoadedSetting() {
      document
        .querySelector(".document")
        .childNodes[
          document.querySelector(".document").childElementCount - 2
        ].classList.add("last");
    },
    async getReferDetail() {
      if (this.GET_REFER_ID !== null) {
        try {
          await this.REFER_DETAIL(this.GET_REFER_ID).then(() => {
            this.requestDetail = this.GET_REFER_DETAIL;
            this.bringReferId = this.GET_REFER_DETAIL.referId;
            if (
              this.GET_REFER_DETAIL.referAttchId !== "" &&
              this.GET_REFER_DETAIL.referAttchId !== null
            ) {
              this.attachFile =
                "https://www.drrefer.net/api/pic/" +
                this.GET_REFER_DETAIL.referAttchId;
            }
            this.isOwnRefer();
          });
        } catch (error) {
          console.log("error :", error);
          this.SET_POPUP(true);
          this.popupSet.title = "서버요청 실패";
          this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
          this.popupSet.popType = "warn";
          this.popupSet.cancelBtnText = "확인";
        }
      } else {
        this.SET_POPUP(true);
        this.popupSet.title = "서버요청 실패";
        this.popupSet.content = " 상세내용을 불러올 수 없습니다.";
        this.popupSet.popType = "custom";
        this.popupSet.confirmBtnText = "확인";
        this.popupSet.destination = true;
        this.popupSet.nextLink = "/v/mypage/request/0";
      }
    },
    setReplyStatus() {
      const status = {
        receive: this.requestDetail.sndHospNm,
        doctor: this.requestDetail.sndDrNm,
        drId: this.requestDetail.sndDrId,
        referId: this.requestDetail.referId,
      };
      this.SET_REPLY_STATUS(status);
    },
    download() {
      window.open(this.attachFile, "_blank");
    },
    goReplyComment(referId) {
      this.$refs.appheader.callReplyComment(referId);
    },
    isOwnRefer() {
      if (this.requestDetail.sndDrId === localStorage.getItem("drId")) {
        this.isOwn = true;
      }
    },
    goModify(referUrl) {
      this.SET_REFER_ID(referUrl);
      this.$router.push("/v/request/request").catch(()=>{});
    },
    async goReplyRequest() {
      /**
      this.SET_REFER_ID(referUrl);
       */
      this.setReplyStatus();
      this.$router.push("/v/request/requestFormReply").catch(()=>{});
    },
  },
  destroyed() {
    this.SET_POPUP(false);
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/join";
.viewer {
  position: relative;
  max-width: 645px;
  &.noneReceive {
    &:after {
      content: "클릭하여 수신병원을 선택해주세요.";
      color: #b7b7b7;
    }
  }
}
</style>
